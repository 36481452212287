// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-page-js": () => import("/opt/build/repo/src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-404-page-js": () => import("/opt/build/repo/src/templates/404-page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-cv-page-js": () => import("/opt/build/repo/src/templates/cv-page.js" /* webpackChunkName: "component---src-templates-cv-page-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-portfolio-page-js": () => import("/opt/build/repo/src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */),
  "component---src-templates-subscribe-page-js": () => import("/opt/build/repo/src/templates/subscribe-page.js" /* webpackChunkName: "component---src-templates-subscribe-page-js" */),
  "component---src-templates-tags-page-js": () => import("/opt/build/repo/src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-project-category-js": () => import("/opt/build/repo/src/templates/project-category.js" /* webpackChunkName: "component---src-templates-project-category-js" */),
  "component---src-templates-project-subcategory-js": () => import("/opt/build/repo/src/templates/project-subcategory.js" /* webpackChunkName: "component---src-templates-project-subcategory-js" */),
  "component---src-templates-project-page-js": () => import("/opt/build/repo/src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

